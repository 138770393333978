import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { LandingComponent } from './components/landing/landing.component';
import { LibraryComponent } from './components/library/library.component';
import { QrValidationComponent } from './components/qr-validation/qr-validation.component';

const routes: Routes = [
  { path: 'landing', component: LandingComponent },
  { path: 'register', component: LandingComponent },
  { path: 'landing/library', component: LibraryComponent },
  { path: 'landing/qr/:id', component: QrValidationComponent },
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
})
export class AppRoutingModule { }

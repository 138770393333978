<div>
  <div class="allNewsSection__tittle">
    <span>Noticias de la semana / mes</span>
  </div>

  <div class="allNewsSection__newsContainer">
    <div class="col-left">
      <ng-container *ngFor="let item of newsOnTheLeft; let i = index">
        <div class="allNewsSection__newsContainer_news">
          <landing-new-data [news]="item" (onClickCard)="showNewsSelected(i*2)"></landing-new-data>
        </div>
      </ng-container>
    </div>
    <div class="col-right">
      <ng-container *ngFor="let item of newsOnTheRight; let i = index">
        <div class="allNewsSection__newsContainer_news">
          <landing-new-data [news]="item" (onClickCard)="showNewsSelected(i*2+1)"></landing-new-data>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="newsSelectedContainer" *ngIf="showNewsSelectedData">
  <div class="newsSelectedContainer__data">
    <div class="newsSelectedContainer__data__close" (click)="showNewsSelectedData = false">
      <i class="icon-i-cancel-details-box"></i>
    </div>
    <div class="newsDataContainer" *ngIf="newsSelected">
      <div class="container">
        <div class="row rowNewView">
          <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4 InfoNoticia">
            <div class="newsDataContainer__info">
              <div class="newsDataContainer__infoTittle">{{ newsSelected.Title }}</div>
              <div class="newsDataContainer__infoCreatedBy">Creado por: {{ newsSelected.careArea.CareAreaName }} - {{ newsSelected.school.NameTSchool }}</div>
              <a *ngIf="newsSelected.linkArticle" href="{{newsSelected.linkArticle}}" target="_blank" rel="noopener">
                <div class="newsLink"><strong>Ver enlace</strong></div>
              </a>
              <div class="newsDataContainer__infoSeparator"></div>
              <div class="newsDataContainer__infoContent">
                <div [innerHTML]="setHtmlContent(newsSelected.Description)"></div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 viewImage">
            <div class="newsDataContainer__img">
              <img src="{{ newsSelected.Imagen }}" class="Noticia" alt="img Noticia">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
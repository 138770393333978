'use strict';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Article } from 'src/app/models/article';
import { ArticleService } from 'src/app/services/article.service';
import { RegisterService } from 'src/app/services/register.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'landing-home',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {

  articles: Article[] = [];
  public selectedFilter: string = 'ciencia';
  private _careAreas: any = undefined;
  public isLoading: boolean = false;
  htmlContent: SafeHtml;
  public DYNAMIC_SCHOOL_PHOTO: any = (id: number = 0) => environment.DYNAMIC_SCHOOL_PHOTO(id);
  public WELCOME_IMAGE: string = environment.WELCOME_IMAGE;
  public SCHOOL_NAME: string = environment.SCHOOL_NAME;
  public PRINCIPAL_APP_NAME: string = environment.principalAppName;
  public STORAGE_ROUTE:string = environment.storage.cedoc +'/modules/logo-cedoc-white.png'


  constructor(
    private _articleService: ArticleService,
    private _registerService: RegisterService,
    private sanitizer: DomSanitizer
  ) {
    //
  }

  ngOnInit() {
    this._articleService.getCareAreas()
      .subscribe({
        next: (resp) => {
          this._careAreas = resp;
          this.getArticles();
        }, error: (err) => {
          console.log(err);
        }
      });
  }

  private getArticles() {
    this.isLoading = true;
    let endDate = new Date(); // Current date
    let startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 4);

    let filter = JSON.stringify({
      where: {
        and: [
          {
            CreateAt: {
              between: [startDate.toISOString(), endDate.toISOString()],
            }
          },
          // {
          //   CareAreaID: { inq: [...this._careAreas[this.selectedFilter]] }
          // },
          {
            IsPrivate: false
          },
          {
            isApproved: true
          }
        ]
      },
      limit: 10,
      order: "id DESC",
      include: ["careArea", 'school']
    })


    this._articleService.newGetArticles(filter).subscribe({
      next: (resp) => {
        this.isLoading = false;
        this.articles = resp

      }, error: (err) => {
        this.isLoading = false;
        console.log(err);
      }
    })
  }
  setHtmlContent( stringHtml) {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(stringHtml);
    return this.htmlContent
  }
  public onSelectFilter(filter: string) {
    this.selectedFilter = filter;
    this.getArticles();
  }

  showHideRegister() {
    this._registerService.nextRegisterBtn(true);
  }

  public openNewsArticle(linkArticle: string) {
    if (linkArticle)
      window.open(linkArticle, '_blank');
  }
  public getImgNews(article: Article): string {
    let imgLink = '';
    if (article.Imagen) imgLink = article.Imagen;
    else imgLink = this.DYNAMIC_SCHOOL_PHOTO(article.SchoolID);
    return imgLink;
  }
}
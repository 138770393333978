<div class="cat__pages__login__header">
  <div class="cat__pages__login__header__logo">
    <a href="javascript: history.back();">
      <img [src]="MAIN_SCHOOL_LOGO" alt="">
      <!-- <div class="logoNuevo hideResp" [ngStyle]="{ 'background-image': 'url(' + MAIN_SCHOOL_LOGO + ')'  }"></div> -->
    </a>
  </div>

  <div class="cat__pages__login__header__menu" [ngClass]="toggleNav ? 'cat__pages__login__header__menu--toggle' : ''">
    <ul class="cat__pages__login__header__menu__list">
      <li class="list-inline-item hideResp" *ngIf="SCHOOL_NAME === 'CEDOC'">
        <a target="_blank" href='https://www.e-collect.com/customers/plus/CENAC_PagosPlus.htm' rel="noopener">PAGOS</a>
      </li>
      <li class="list-inline-item hideResp">
        <a href="/landing">INICIO</a>
      </li>
      <li class="list-inline-item">
        <a routerLink="/privacy-policy" target="_blank">TÉRMINOS Y CONDICIONES</a>
      </li>
      <li class="list-inline-item">
        <a routerLink="/landing/library">Bibliotecas</a>
      </li>
    </ul>

    <div class="cat__pages__login__header__menu__actions">
      <button type="button" class="button register-button" (click)="this.registerBox.emit()">
        REGISTRARSE
      </button>

      <button type="button"  class="button login-button" (click)="loginBox.emit()">
        INGRESAR
      </button>
    </div>
  </div>

  <div class="expand-button" [ngClass]="toggleNav ? 'expand-button--toggle' : ''" (click)="toggleNav = !toggleNav">
    <span class="expand-button__bar"></span>
    <span class="expand-button__bar" *ngIf="!toggleNav"></span>
    <span class="expand-button__bar"></span>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RegisterService } from './services/register.service';

@Component({
  selector: 'landing-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'landing';

  showLoginBox: boolean = false;
  showRegisterBox: boolean = false;

  private subscriptionRegister: Subscription;

  constructor(
    private _registerService: RegisterService,
  ) {
    this.subscriptionRegister = this._registerService.sharedRegisterBtn.subscribe(data => {
      if (data) this.showHideRegister();
    });
  }


  ngOnDestroy() {
    this.subscriptionRegister.unsubscribe();
  }


  showHideLogin() {
    this.showLoginBox = !this.showLoginBox;
  }
  showHideRegister() {
    this.showRegisterBox = !this.showRegisterBox;
  }
  showHideRegisterAndLogin() {
    this.showHideLogin();
    this.showHideRegister();
  }
}

import { schoolToLoad } from "./environment.common";

export const environment = {
  production: false,
  UrlApiV1: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/api',
  UrlApiV2: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api',
  UrlBackV1: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/',
  DepartmentsApi: 'https://www.datos.gov.co/resource/xdk5-pm3f.json',

  registerCloudFunction: 'https://us-central1-cedoc-360c.cloudfunctions.net/dev-userRegister/register',

  principalAppName: 'cedoc',
  SHOW_FOOTER: true,
  EMAIL_CLIENT_ID: "682964296600-0eg8o061ibjlbk1tb7t86op0u5ht2mmu.apps.googleusercontent.com",

  ...schoolToLoad()
};

<div class="qr-validation"
[ngStyle]="{'background-image': DYNAMIC_BG(1) }">
  <div class="qr-validation__container">
    <ng-container *ngIf="requestInfo !== undefined">
      <div class="qr-check">
        <i class="icon-solicitud-radicada icon-solicitud-radicada--check"></i>
        <h2 class="qr-text qr-text--bold qr-text--green qr-text--lg">ESTE DOCUMENTO ES VÁLIDO</h2>
      </div>

      <div class="qr-content">
        <div class="qr-content__verification">
          <div class="qr-content__verification-state">
            <p class="qr-text">VERIFICACIÓN DE:</p>
            <p class="qr-text qr-text--bold">{{ requestInfo.requestTypeRequest.nameTypeRequest || '' | uppercase }}</p>
            <p class="qr-text qr-text--bold">
              {{ requestInfo.requestSubTypeRequest.nameSubTypeRequest || '' | uppercase }}</p>
          </div>
          <a [href]="UrlApiV1 + requestInfo.documentLink" target="_blank"
            class="button button--download">
            Descargar
            <i class="icon-i-download"></i>
          </a>
        </div>
        <p class="qr-text qr-text--bold qr-text--center">ESTE DOCUMENTO LÍCITO PERTENECE A: </p>
        <p class="qr-text qr-text--bold qr-text--center">
          {{ requestInfo.requestUserOwner.MilitarInfos?.[0]?.Range || '' | uppercase}}
          {{ requestInfo.requestUserOwner.Name1 || '' | uppercase }}
          {{ requestInfo.requestUserOwner.Name2 || '' | uppercase }}
          {{ requestInfo.requestUserOwner.LastName1 || '' | uppercase }}
          {{ requestInfo.requestUserOwner.LastName2 || '' | uppercase }}
        </p>
        <p class="qr-text qr-text--center qr-mb-1">
          Alumno de {{ requestInfo.requestSchool.NameTSchool || '' | titlecase  }}
        </p>
        <p class="qr-text qr-text--bold qr-text--center">PARA:</p>
        <p class="qr-text qr-text--center">{{ requestInfo.requestCourseOffer?.course?.NameCourse || 'N/A'}}</p>

        <div class="qr-content__validation-container">
          <p class="qr-text qr-text--bold qr-mb-05">APRUEBAN VALIDEZ:</p>

          <ng-container *ngFor="let member of requestInfo.reqMembers">
            <div class="qr-content__validation">
              <i class="icon-i-chulo"></i>
              <p class="qr-text qr-text--bold">
                {{ member.userapp.Name1 || '' | uppercase }}
                {{ member.userapp.Name2 || '' | uppercase }}
                {{ member.userapp.LastName1 || '' | uppercase }}
                {{ member.userapp.LastName2 || '' | uppercase }}
                <span class="qr-text--normal">
                  {{ member.userapp.CedocEmail }}
                </span>
              </p>
            </div>
          </ng-container>
          <p class="qr-text qr-text--bold qr-text--sm qr-mt-05">Fecha:
            <span class="qr-text--normal">{{ requestInfo.updateDate | date: 'dd/MM/yyyy' }}
            </span>
          </p>
        </div>

        <div class="qr-content__logos">
          <div class="logo-container">
            <img [src]="DYNAMIC_SCHOOL_LOGO_SVG(requestInfo.requestSchool.id)" alt="" width="90px">
            <p class="qr-text--bold qr-text">{{requestInfo.requestSchool.SchoolAcronim}}</p>
          </div>
          <div class="logo-container">
            <p class="qr-text"><span class="qr-text qr-text--bold">Código SNIES
              </span>{{ requestInfo.requestSchool.SNIES || 'N/A' }} </p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <div class="skeleton-container">
        <div class="skeleton skeleton-box--sm"></div>
        <div class="skeleton skeleton-box--sm"></div>
        <div class="skeleton skeleton-box--sm"></div>
        <div class="skeleton skeleton-box--lg"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoading && requestInfo === undefined">
      <div class="no-info">
        <em class="icon-i-close"></em>
        <div class="no-info__content qr-text--center">
          <h2 class="qr-text qr-text--bold qr-mb-05">No se econtraron resultados</h2>
          <p class="qr-text qr-text--gray qr-text--sm">Inténtalo de nuevo con un valor diferente</p>
          <a routerLink="#" class="button button--back">Regresear</a>
        </div>
      </div>
    </ng-container>

  </div>

</div>
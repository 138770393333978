<div class="login">
  <i class="icon-i-close" (click)="showHideLogin()"></i>

  <div class="login-box">
    <i class="icon-i-user-login"></i>
    <h2 class="login-title" *ngIf="showDataLogin != 3">Ingresar como:</h2>
    <h2 class="login-title" *ngIf="showDataLogin == 3">Restablecer Contraseña:</h2>

    <div class="login-container" *ngIf="showDataLogin == 0 ;">
      <button id="googleBtn"  type="button"
        class="button button--blue button--icon">
        <i class="fa fa-google"></i>
        <strong> INGRESAR </strong>
      </button>

      <div class="button button--blue" (click)="showDataLogin = 1">
        Aspirante{{ SCHOOL_NAME == 'CELIC' ? '/Acudiente' : '' }}
      </div>

      <div class="button button--blue" (click)="showHideLoginRegister()">
        ¿Aún no tienes una cuenta?
      </div>
    </div>

    <div class="login-container" *ngIf="showDataLogin == 3; ">
      <div class="form-container" >
        <form #f="ngForm" class="form formRestablecer" (ngSubmit)="restableceContrasena(f.value, f.valid) ">
          <div>
            <div class="form-input">
              <em class="icon-i-profile"></em>
              <input type="email" name="Email" placeholder="Correo personal de aspirante{{ SCHOOL_NAME == 'CELIC' ? '/acudiente' : '' }}"
                [ngModel]="user.Email" required maxlength="50" pattern="^([\w\.-]+)@(\w+\.)+(\w+)$" #Email="ngModel" />
            </div>
            <ul class="form-input__error-list">
              <li *ngIf="!(Email.valid || (Email.pristine && !f.submitted))">
                Digite un correo válido.
              </li>
            </ul>
          </div>
          <div>
            <div class="form-input">
              <em class="icon-i-list-icon"></em>
              <input type="text" name="UserDocument" placeholder="Numero de Documento" [ngModel]="user.UserDocuments" required minlength="5"
                maxlength="20" #UserDocument="ngModel" />
            </div>
            <ul class="form-input__error-list">
              <li *ngIf="!( UserDocument.valid || (UserDocument.pristine && !f.submitted))">
                Digite un documento válido.
              </li>
            </ul>
          </div>
          <div>
            <div class="recaptchaRestablecer " >
              <script src="https://www.google.com/recaptcha/api.js?onload=CaptchaCallback&render=explicit" async
              defer></script>
              <re-captcha [(ngModel)]="formModel.captcha" name="captcha" required siteKey="6LdPSnkUAAAAANUJGmUtAjHNtF8ctRTkmlA-xdqc" #captchaControl="ngModel"></re-captcha>
            </div>
          </div>
          <button type="submit"  class="button button--blue" *ngIf="{disabled}?'disabled':''">
            Restablecer Contraseña
          </button>
          <button (click)="showDataLogin =1 "  class="button button--blue">
            Cancelar
          </button>
        </form>
      </div>
    </div>


    <div *ngIf="showDataLogin == 1">
      <div class="form-container">
        <h2 class="form-container__title">Aspirante{{ SCHOOL_NAME == 'CELIC' ? '/Acudiente' : '' }}</h2>

        <form #f="ngForm" novalidate class="form" (ngSubmit)="logInCandidate(f.value, f.valid)">
          <div>
            <div class="form-input">
              <em class="icon-i-profile"></em>
              <input type="email" name="Email" placeholder="Correo de ingreso aspirante{{ SCHOOL_NAME == 'CELIC' ? '/acudiente' : '' }}"
                [ngModel]="user.Email" required maxlength="50" pattern="^([\w\.-]+)@(\w+\.)+(\w+)$" #Email="ngModel" />
            </div>

            <ul class="form-input__error-list">
              <li *ngIf="!(Email.valid || (Email.pristine && !f.submitted))">
                Digite un correo válido.
              </li>
            </ul>
          </div>

          <div>
            <div class="form-input">
              <em class="icon-reestablecer-contrasena"></em>
              <input type="password" name="CellPhone" placeholder="Contraseña o número de teléfono" [ngModel]="user.CellPhone" required minlength="5"
                maxlength="40" #CellPhone="ngModel" autocomplete="on" />
            </div>
            
            <strong (click)="olvidoContrasena()" class="textoOlvidoCredenciales">Olvidaste tus credenciales </strong>

            <ul class="form-input__error-list">
              <li *ngIf="!(CellPhone.valid || (CellPhone.pristine && !f.submitted))">
                Digite un número válido.
              </li>
            </ul>
          </div>

          <div >
            <script src="https://www.google.com/recaptcha/api.js?onload=CaptchaCallback&render=explicit" async
            defer></script>
            <re-captcha [(ngModel)]="formModel.captcha" name="captcha" required siteKey="6LdPSnkUAAAAANUJGmUtAjHNtF8ctRTkmlA-xdqc" #captchaControl="ngModel"></re-captcha>
            <ul class="form-input__error-list">
              <li *ngIf="!(captchaControl.valid || (captchaControl.pristine && !f.submitted))">
                El Captcha debe ser resuelto.
              </li>
            </ul>
          </div>

          <ul class="form-input__error-list">
            <li *ngIf="showErrorLoginCandidate">
              Credenciales invalidas
            </li>
          </ul>

          <button type="submit" class="button button--blue">
            Login
          </button>

          <div class="button button--blue" (click)="showHideLoginRegister()">
            Si no tiene una cuenta, ingrese aquí
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
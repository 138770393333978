<div class="cat__pages__login--fullscreen" style="background-color: #FFFFFF">
  <!-- navbar -->
  <div class="cat__pages__login__header" style="padding: 0px" *ngIf="true">
    <landing-navbar (loginBox)="showHideLogin()" (registerBox)="showHideRegister()"></landing-navbar>
  </div>
  <!-- LoginBox -->
  <div  [ngStyle]="{'visibility': showLoginBox  ? 'visible' : 'hidden'}"    >
    <landing-login-card (closeBox)="showHideLogin()" (changeBox)="showHideRegisterAndLogin()"></landing-login-card>
  </div>
  <!-- RegisterBox -->
  <div *ngIf="showRegisterBox">
    <landing-register-card (closeBox)="showHideRegister()" (changeBox)="showHideRegisterAndLogin()"></landing-register-card>
  </div>
  <!-- Pages -->
  <router-outlet></router-outlet>
  <!-- Footer -->
  <landing-footer></landing-footer>
</div>
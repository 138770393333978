import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpService } from './http.service';
import { Article } from '../models/article';

@Injectable()
export class ArticleService {

  constructor(
    private _httpService: HttpService,
  ) { }

  getArticles(careAreas: number[]): Observable<Article[]> {
    const endDate = new Date(); // Current date
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 4);

    const filter = JSON.stringify({
      where: {
        and: [
          {
            CreateAt: {
              between: [startDate.toISOString(), endDate.toISOString()],
            }
          },
          {
            CareAreaID: { inq: [...careAreas] }
          }
        ]
      },
      limit: 8,
      order: "id DESC",
      include: ["careArea", 'school']
    })

    const url: string = `/Articles?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  newGetArticles(filter: string): Observable<Article[]> {
    const url: string = `/Articles?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  getCareAreas(): Observable<any[]> {
    const url: string = '/CareAreas';
    return this._httpService.httpGet(url).pipe(
      map((resp: any[]) => {
        const careAreas: any = {};

        careAreas['bienestar'] = resp.filter(e => e.CareAreaName.includes('Bienestar'))
          .map(e => e.id);

        careAreas['extension'] = resp.filter(e => e.CareAreaName.includes('Extensión'))
          .map(e => e.id);

        careAreas['bibliotecas'] = resp.filter(e => e.CareAreaName.includes('Bibliotecas'))
          .map(e => e.id);

        const excludedFilters = ['Bienestar', 'Extensión', 'Bibliotecas'];
        careAreas['ciencia'] = resp.filter(careArea => !excludedFilters.some(e => careArea.CareAreaName.includes(e)))
          .map(e => e.id);

        return careAreas;
      })
    );
  }
}

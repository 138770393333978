import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'landing-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Output('loginBox') loginBox: EventEmitter<any> = new EventEmitter();
  @Output('registerBox') registerBox: EventEmitter<any> = new EventEmitter();

  constructor() {
    //
  }

  public toggleNav:boolean = false;
  public DYNAMIC_SCHOOL_LOGO_SVG:any = (id:number) => environment.DYNAMIC_SCHOOL_LOGO_SVG(id);
  public MAIN_SCHOOL_LOGO:any = environment.MAIN_SCHOOL_LOGO;
  public SCHOOL_NAME:string = environment.SCHOOL_NAME;

  ngOnInit(): void {
    //
  }
}

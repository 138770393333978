import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Article } from 'src/app/models/article';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'landing-new-data',
  templateUrl: './new-data.component.html',
  styleUrls: [ './new-data.component.css' ]
} )
export class NewDataComponent implements OnInit {

  @Output( 'onClickCard' ) onClickCard: EventEmitter<void> = new EventEmitter();
  htmlContent: SafeHtml;
  private _news:Article = {} as Article;
  @Input() set news(value:Article){
    this._news = value;

    if (!this.news.Imagen) {
      this.imgNews = this.DYNAMIC_SCHOOL_PHOTO(this.news.SchoolID);
    } else {
      this.imgNews = this.news.Imagen;
    }

  }

  get news():Article{
    return this._news;
  }

  imgNews: string;

  
  public DYNAMIC_SCHOOL_PHOTO:any = (id:number = 0) => environment.DYNAMIC_SCHOOL_PHOTO(id);
  
  constructor (private _element:ElementRef, private sanitizer: DomSanitizer) {
    //
  }

  clickedCard( event ) {
    this.onClickCard.emit()
  }

  openNew( event ) {
    event.stopPropagation();
  }

  ngOnInit(): void {
    //
  }

  setHtmlContent( stringHtml) {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(stringHtml);
    return this.htmlContent
  }
  //Use host listener on screen resize to update in realtime
  setHeight(): void {
    const newsImgElement = this._element.nativeElement.querySelector('.newsImg');
    const imgElement = newsImgElement.querySelector('img');

    if (imgElement) {
      const naturalHeight = imgElement.height ;
      const newHeight = naturalHeight / 2;
      newsImgElement.style.height = (newHeight + 20) + 'px';
      newsImgElement.style.marginTop = newHeight + 'px';
    }

  }
}

'use strict';
import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/models/article';
import { ArticleService } from 'src/app/services/article.service';
import { environment } from 'src/environments/environment';

declare const google: any;
var ctx: any;

@Component({
  selector: 'landing-library',
  templateUrl: './library.component.html',
  styleUrls: [ './library.component.css' ],
})
export class LibraryComponent implements OnInit {
  query: string = '';
  articles: Article[] = [];
  newsSelected: Article;
  showNewsSelectedData: boolean = false;

  public LIBRARY_ICON:any = environment.LIBRARY_ICON;
  public DYNAMIC_LIBRARY_PHOTO:any = (id:string) => environment.DYNAMIC_LIBRARY_PHOTO(id);
  public DYNAMIC_SCHOOL_PHOTO:any = (id:number = 0) => environment.DYNAMIC_SCHOOL_PHOTO(id);
  public SCHOOL_NAME:string = environment.SCHOOL_NAME;

  public libraryList = [
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('CELIC'),
      school:"Liceos Del Ejército Nacional'",
      name:"Biblioteca General Colegio Bachillerato Patria",
      person:"Jessica Gonzalez",
      address:"Cl. 101a #11b-11, Bogotá",
      schedule:"Lunes a Viernes: 06:30 horas a 13:00",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('EMSUB'),
      school:"Escuela Militar de Suboficiales 'Sargento Inocencio Chincá'",
      name:"Biblioteca EMSUB",
      person:"Fredy Alexander Ariza Simbaqueva      ",
      address:"Tolemaida, Las Ceibas, Nilo, Cundinamarca      ",
      schedule:"Lunes a Viernes: 08:30 horas a 12:30 horas y 13:30 a 17:00 horas",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('ESMIC'),
      school:"Escuela Militar de Cadetes José María Cordova",
      name:"Biblioteca General Nelson Mejía Henao",
      person:"Laura Carolina Villate León      ",
      address:"Calle 80 No. 38-00, Bogotá      ",
      schedule:"Lunes a Viernes: 06:30 horas a 21:00 horas      ",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('ESACE'),
      school:"Escuela de Armas Combinadas del Ejército ",
      name:"Biblioteca de la Escuela de Armas Combinadas del Ejército",
      person:"Janneth Patricia Morales Saavedra      ",
      address:"Calle 102 No. 7-80, Bogotá      ",
      schedule:"Lunes a Jueves: 07:00 horas a 12:30 y 14:00 horas a 17:30 horas      ",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('ESING'),
      school:"Escuela de Ingenieros Militares ejército           ",
      name:"Biblioteca CR. Lino de Pombo",
      person:"María Cristina Parra Ovalle      ",
      address:"Carrera 46 No. 30-18, Bogotá      ",
      schedule:"Lunes a Viernes: 8:00 horas a 19:00       ",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('ESLOG'),
      school:"Escuela de Logística ",
      name:"Biblioteca ESLOG",
      person:"Giovanni Humberto Robayo ",
      address:"Calle 11 Sur No. 16 Este 99, Bogotá      ",
      schedule:"Lunes a Viernes: 16:00 horas a 22:00 horas",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('ESICI'),
      school:"Escuela de Inteligencia y Contrainteligencia BG. Ricardo Charry Solano",
      name:"Biblioteca Agente de Inteligencia Prada Figueroa Giovanni ",
      person:"Pillip Ginhette Vanegas García      ",
      address:"Carrera 8 No. 101-33, Bogotá      ",
      schedule:"Lunes a Jueves: 7:00 horas a 12:00 horas y 13:00 horas  a 14:00 horas      ",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('ESCOM'),
      school:"Escuela de Comunicaciones del Ejército",
      name:"Biblioteca ESCOM",
      person:"",
      address:"Calle 5 No.15-00, Facatativá, Cundinamarca      ",
      schedule:"Lunes a Viernes 08:00 horas - 14:00 horas",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('ESCAB'),
      school:"Escuela de Caballería del Ejército Nacional de Colombia",
      name:"Biblioteca ESCAB",
      person:"",
      address:"Carrera 7 No. 106-10, Bogotá      ",
      schedule:"Lunes a Viernes 08:00 horas - 14:00 horas",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('ESART'),
      school:"Escuela de Artillería General Carlos Julio Gil Colorado del Ejército Nacional",
      name:"Biblioteca ESART",
      person:"",
      address:"Calle 102 No 7-80, Bogotá      ",
      schedule:"Lunes a Viernes 08:00 horas - 14:00 horas",
    },
    {
      picture: environment.DYNAMIC_LIBRARY_PHOTO('ESAVE'),
      school:"Escuela de Aviación del Ejército",
      name:"Biblioteca ESAVE",
      person:"",
      address:"Comando Aéreo de Transporte Militar CATAM      ",
      schedule:"Lunes a Viernes: 07:00 horas a 12:00 horas y  14:00 horas a 18:00 horas",
    },
  ]

  private _careAreas: any = undefined;

  constructor(
    private _articleService: ArticleService,
  ) {
    //
  }

  ngOnInit(): void {
    this._articleService.getCareAreas()
      .subscribe({
        next: (resp) => {
          this._careAreas = resp;
          this.getArticles();
        }, error: (err) => {
          console.log(err);
        }
      });
  }

  private getArticles() {
    this._articleService.getArticles(this._careAreas['bibliotecas']).subscribe({
      next: (resp) => {
        this.articles = resp;
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  public showNewsSelected(index: number) {
    this.newsSelected = this.articles[index];
    if (!this.newsSelected.Imagen) this.newsSelected.Imagen = this.DYNAMIC_SCHOOL_PHOTO(this.newsSelected.SchoolID);
    if (!this.newsSelected.Description) this.newsSelected.Description = this.newsSelected.Summary;
    this.showNewsSelectedData = true;
  }
}
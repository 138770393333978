import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class CoursesService {

  private baseUrlv1: string = environment.UrlApiV1;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

  getAllSchools(): Observable<any> {
    const filter = JSON.stringify({
      include:["AcademicAreas"],
      order: 'NameTSchool ASC',
      where: {
        id: {
          neq: 25
        }
      }
    });

    let schools$ = this.http
      .get(`${this.baseUrlv1}/Schools?filter=${filter}`, { headers: this.getHeaders() })
      .pipe(
          catchError(err => this.authService.errorMessages(err))
      )
    return schools$;
  }

  getAllAcademicAreasBySchool(id: number | undefined): Observable<any> {
    let modules$
    if (id == 0) {
      modules$ = this.http
        .get(`${this.baseUrlv1}/AcademicaAreas`, { headers: this.getHeaders() })
        
        .pipe(
          catchError(err => this.authService.errorMessages(err))
      )
    } else {
      modules$ = this.http
        .get(`${this.baseUrlv1}/Schools/${id}/AcademicAreas`, { headers: this.getHeaders() })
        
        .pipe(
          catchError(err => this.authService.errorMessages(err))
      )
    }
    return modules$;
  }

  getAllCoursesOfersBySchool(id: number | undefined): Observable<any> {
    const today = new Date();
    const courses$ = this.http
    .get(`${this.baseUrlv1}/Schools/${id}/CourseOfers?filter={"where": {"and": [ {"InscriptStart":{"lt": "${today}"}},{"InscriptEnd":{"gt": "${today}"}}]},"include" : ["course"] }`, { headers: this.getHeaders() })    
    .pipe(
      catchError(err => this.authService.errorMessages(err))
    )

    return courses$;
  }


  public getDepartments() {
    return this.http.get( `${ environment.DepartmentsApi }?$select=departamento&$group=departamento&$order=departamento` ).pipe(
      map( (resp:any) => resp.map( (department:any) => department.departamento))
    );
  }

  public getCities( department: string ) {
    return this.http.get( `${ environment.DepartmentsApi }?$select=municipio&$where=departamento='${ department }'` ).pipe(
      map( (resp:any) => resp.map( (city:any) => city.municipio))
    );
  }

}

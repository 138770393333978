

//Here we will store all the variables required by the components.
interface SchoolToLoadOptions {
  school?: string;
  mainSchoolLogo?: string;
  libraryIcon?: string;
}


//Default storages
const storage = {
  cedoc: 'https://storage.googleapis.com/assets_cedoc',
  celic: 'https://storage.googleapis.com/celic_assets',
}

/**
 * Default school variables
 * @param SCHOOL 
 * @returns 
 */
export const schoolToLoad = (
  { //If we want, we can initialize them with default values
    school = 'cedoc',
    mainSchoolLogo = 'logo-cedoc',
    libraryIcon = 'BibliotecasEjercito',
  }: SchoolToLoadOptions = {}) => {
  return {
    WELCOME_IMAGE: `${storage[school]}/modules/Welcome.png`,
    MAIN_SCHOOL_LOGO: `${storage[school]}/modules/${mainSchoolLogo}.png`,
    LIBRARY_ICON: `${storage[school]}/Iconos/${libraryIcon}.png`,
    storage:storage,
    LINK_LOGOS: {
      LOGO_01: `${storage[school]}/modules/pages/common/img/links/logos-01.png`,
      LOGO_02: `${storage[school]}/modules/pages/common/img/links/logos-02.png`,
      LOGO_03: `${storage[school]}/modules/pages/common/img/links/logos-03.png`,
      LOGO_04: `${storage[school]}/modules/pages/common/img/links/logos-04.png`,
      LOGO_05: `${storage[school]}/modules/pages/common/img/links/logos-05.png`,
      CERTIFICATION: `${storage[school]}/modules/pages/common/img/links/certificacion.png`,
    },

    DYNAMIC_LIBRARY_PHOTO: (id: string = '') => `${storage[school]}/schools/library/${id}.jpg`,
    DYNAMIC_SCHOOL_LOGO_SVG: (id: number = 0) => `${storage[school]}/schools/logo/${id}.svg`,
    DYNAMIC_SCHOOL_PHOTO: (id: number = 0) => `${storage[school]}/modules/pages/common/img/photos/esc${id}.jpg`,
    DYNAMIC_BG: (bg: number = 0) => `${storage[school]}/background-images/${bg}.png`,

    SCHOOL_NAME: school.toUpperCase() //Uppercase Just in case
  }
}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestI } from '../models/request/request';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class QrService {

  constructor(
    private http: HttpService,
  ) { }


  /**
   * obtains a list of requests
   * @param filter 
   * @returns 
   */
  getRequest(filter?:string): Observable<RequestI>{
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this.http.httpGet(`/Requests${ newFilter }`);
  }

  /**
   * obtains a list of requests
   * @param filter 
   * @returns 
   */
  getWorkInfo(filter?:string): Observable<RequestI>{
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this.http.httpGet(`/WorkInfos${ newFilter }`);
  }

}

<div class="generalContainer">
  <div class="newsContainer">
    <div class="newsImg">
      <ng-container>
        <img src="{{ imgNews }}" class="news-img" alt="img" (click)="clickedCard($event)" (load)="setHeight()">
      </ng-container>
    </div>

    <div class="newsTextContainer">
      <div class="newsTittle" (click)="clickedCard($event)">{{ news.Title }}</div>
      <br>
      <!-- <div class="newsCreated">{{ news.CreateAt | date }}</div> -->
      <div class="newsCreated">Creado por: {{ news.careArea.CareAreaName }} - {{ news.school.NameTSchool }}</div>
      <a *ngIf="news.linkArticle" href="{{news.linkArticle}}" target="_blank" rel="noopener" (click)="openNew($event)">
        <div class="newsLink"><strong>Ver enlace</strong></div>
      </a>
      <div class="separator"></div>
      <ng-container *ngIf="news.Summary; else newsDescrip">
        <div class="newsDesc">{{ news.Summary }}</div>
      </ng-container>
      <ng-template #newsDescrip>
        <div class="newsDesc">
          <div [innerHTML]="setHtmlContent(news.Description )"></div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'landing-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.css' ]
} )
export class FooterComponent implements OnInit {

  constructor () { }

  public currentDate: Date = new Date();
  public LINK_LOGOS:any = environment.LINK_LOGOS;
  public SCHOOL_NAME:string = environment.SCHOOL_NAME;
  public SHOW_FOOTER = environment.SHOW_FOOTER;
  public principalAppName= environment.principalAppName;

  ngOnInit(): void {
    //
  }

}

<div class="news-page">
  <div class="news-page__welcome welcomeSec" [ngStyle]="{'background-image': 'url(' + WELCOME_IMAGE + ')' }">
    <div class="welcome-section welcomeSec__secction--left"  *ngIf="PRINCIPAL_APP_NAME=='cedoc'">
      
      <div class="welcome-section__description">
        <img [src]='STORAGE_ROUTE'  class="imagenLandringWhite"/>
        <br><br>
        <span class="InfoInicial"><strong>Aprendiendo con Valor, Creciendo con Honor</strong><br><br> CEDOC 360 es una plataforma que 
          implementa la innovación y seguridad en la gestión académico-administrativa que busca 
          facilitar la labor del personal administrativo, docentes y estudiantes; 
          sistematizando los procesos de las unidades educativas mejorando la trazabilidad generando 
          eficiencia y transparencia.</span>
      </div>
      <div class="welcome-section__btn-container">
        <button (click)="showHideRegister()">REGISTRARSE</button>
      </div>
    </div>


    <div class="welcome-section-celic welcomeSec__secction--left"  *ngIf="PRINCIPAL_APP_NAME=='celic'">
      <div class="welcome-section__tittle">
        <span>¿QUIÉNES SOMOS?</span><br>
      </div>
      <div class="welcome-section__description-celic">
        <span>

          Los Liceos del Ejército Nacional son instituciones educativas de régimen especial, de acuerdo con la resolución no. 06500 del 3 de agosto de 1994 del Ministerio de Educación Nacional.
<p><br>
Se rigen por normas y políticas del Comando del Ejército Nacional de Colombia y la Dirección General de los Liceos en lo relacionado con el otorgamiento de cupos, procesos de contratación, administración de personal, vinculación laboral y prestacional.
 <p>
En los aspectos académicos de promoción y títulos curriculares, de calendario, PEI, Gobierno Escolar se rigen por las normas del Ministerio de Educación Nacional. ley 115 / 94, ley 715 de 2001, decreto 1860 / 94 y decreto 1290 / 2009.

        </span>
      </div>
      <div class="welcome-section__btn-container">
        <button (click)="showHideRegister()">REGISTRARSE</button>
      </div>
    </div>



    <div class="welcome-section welcomeSec__secction--right" *ngIf="PRINCIPAL_APP_NAME=='cedoc'">
      <div class="tabs-section">
        <div class="tabs-section__tabs-container">
          <label class="tabs-container__tab"
            [ngClass]="[
              selectedFilter === 'ciencia' ? 'tabs-container__tab--selected' : '',
              isLoading ? 'disabled': '',
            ]">
            Ciencia y tecnología
            <input type="radio" hidden name="radio" (change)="onSelectFilter('ciencia')" >
            <span class="checkmark"></span>
          </label>
          <label class="tabs-container__tab"
            [ngClass]="[
              selectedFilter === 'bienestar' ? 'tabs-container__tab--selected': '',
              isLoading ? 'disabled': ''
            ]">
            Bienestar
            <input type="radio" hidden name="radio" (change)="onSelectFilter('bienestar')" >
            <span class="checkmark"></span>
          </label>
          <label class="tabs-container__tab"
            [ngClass]="[
              selectedFilter === 'extension' ? 'tabs-container__tab--selected': '',
              isLoading ? 'disabled': ''
            ]">
            Extensión
            <input type="radio" checked="checked" hidden name="radio" (change)="onSelectFilter('extension')">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>


    <div class="welcome-section welcomeSec__secction--right" *ngIf="PRINCIPAL_APP_NAME=='celic'">
      <div class="tabs-section">
        <div class="tabs-section__tabs-container">
          <label class="tabs-container__tab"
            [ngClass]="[
              selectedFilter === 'ciencia' ? 'tabs-container__tab--selected' : '',
              isLoading ? 'disabled': '',
            ]">
            Noticias
            <input type="radio" hidden name="radio" (change)="onSelectFilter('ciencia')" >
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="articles.length > 0">
    <div class="newsPage__carouselNews carouselNews">
      <div id="carouselNewsControls" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li 
            *ngFor="let item of articles; index as i"
            data-target="#carouselNewsControls"
            [attr.data-slide-to]="i"
            [ngClass]="[ i === 0 ? 'active' : '' ]"
          ></li>
        </ol>

        <div class="carousel-inner">
          <ng-container *ngFor="let item of articles | slice:0:3; let i = index; first as isFirst">
            <div class="carousel-item" [class.active]="isFirst"  *ngIf="item.isApproved">
              <div class="container containerCarousel">
                <div class="row rowNewView">
                  <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 InfoNoticia">
                    <div >
                      <span (click)="openNewsArticle(item.linkArticle)" target="_blank" class="news-data__info-tittle">{{ item.Title }}</span>
                      <div class="news-data__info-by">Creado por: {{ item.careArea.CareAreaName }} - {{ item.school.NameTSchool }}</div>
                      <ng-container *ngIf="item.linkArticle">
                        <a href="{{item.linkArticle}}" target="_blank" rel="noopener">
                          <div class="newsLink"><strong>Ver enlace</strong></div>
                        </a>
                      </ng-container>
                      <div class="news-data__info-separator"></div>
                      <div [innerHTML]="setHtmlContent(item.Description)"></div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 InfoImage">
                    <div class="news-data__container-img">
                      <img src="{{ getImgNews(item) }}" class=" Noticia" alt="img">
                    </div>
                  </div>
              </div>

              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="news-page__all-news" *ngIf="articles.length > 0">
      <news-view [articles]="articles"></news-view>
    </div>
  </ng-container>

  <ng-container *ngIf="articles.length == 0">
    <div class="noNewsToShow">
      <span>No hay noticias en esta sección.</span>
    </div>
  </ng-container>
</div>
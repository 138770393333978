import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private registerBtn = new BehaviorSubject(Boolean());
  sharedRegisterBtn = this.registerBtn.asObservable();

  constructor() { }

  nextRegisterBtn(registerBtn: boolean) {
    this.registerBtn.next(registerBtn);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestI } from 'src/app/models/request/request';
import { QrService } from 'src/app/services/qr.service';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'landing-qr-validation',
  templateUrl: './qr-validation.component.html',
  styleUrls: [ './qr-validation.component.css' ]
} )
export class QrValidationComponent implements OnInit {

  constructor ( private _qrService: QrService, private _activeRoute: ActivatedRoute ) {
    //
  }
  
  //stores the Request information
  public requestInfo?: RequestI = undefined;

  //checks whether or not data is being loaded
  public isLoading: boolean = false;

  public DYNAMIC_SCHOOL_LOGO_SVG:any = (id:number) => environment.DYNAMIC_SCHOOL_LOGO_SVG(id);
  public DYNAMIC_BG:any = (id:number) => environment.DYNAMIC_BG(id);
  public UrlApiV1:any = environment.UrlApiV1;


  ngOnInit(): void {

    this.isLoading = true;
    let filter = JSON.stringify( {
      where: { uuid: this._activeRoute.snapshot.paramMap.get( 'id' ) },
      include: [ { requestUserOwner: 'MilitarInfos' }, 'requestSchool', 'requestCourse', {requestCourseOffer:'course'}, { reqMembers: "userapp" },'requestTypeRequest','requestSubTypeRequest' ]
    } );

    this._qrService.getRequest( filter ).pipe(
    ).subscribe( {
      next: ( resp: any ) => {
        this.requestInfo = resp[ 0 ];
        if ( this.requestInfo === undefined )
          setTimeout( () => { this.isLoading = false; }, 2000 );
        else
          this.isLoading = false;

      }, error: ( err ) => console.log( err )
    } )
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable( {
  providedIn: 'root'
} )
export class AuthService {

  private token!: string;
  //public UrlBackV1: string = environment.UrlBackV1;

  constructor (
    private http: HttpClient,
    private router: Router,
  ) { }

  getToken() {
    let token = localStorage.getItem( 'accessToken' );
    this.token = '';
    if ( !!token ) this.token = token;
    return this.token;
  }

  loginGoogle( data: object ): Observable<any> {
    console.log( 'loginGoogle:', data );
    let url = 'auth/client';
    let url2 = environment.UrlBackV1 + url;
    console.log( 'url2', url2 );
    return this.http.post( url2, data )
      .pipe(
        take(1)
        // catchError( err => this.errorMessages( err ) ) /** TODO: Review error managment */
      )
  }

  errorMessages( error: any ) {
    console.log( 'error:', error );

    let errorMsg = "Error de red...";
    let err = `Details: Problemas de conexion`;
    if ( error.status == 422 ) {
      err = error.error.error ? error.error.error.message : `Details: Error de Datos`;
      let errd = err.split( "Details: " );
      errorMsg = errd[ 1 ];
    }
    if ( error.status == 401 ) {
      errorMsg = `Details: Error De Autenticación (401)`;
    }
    return throwError( errorMsg );
  }

  setToken( token: string ) {
    localStorage.setItem( "accessToken", token );
  }

}

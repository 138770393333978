<div class="containerWhite containerFooter">
  <div class="cat__content" *ngIf="SHOW_FOOTER">
    <div class="cat__footer" style="background: white">
      <div class="cat__footer__top">
        <div class="row">
          <div class="col-md-1 ">
          </div>
          <div class="col-md-10 ">
            <div style="text-align:center;" *ngIf="principalAppName=='cedoc'">
              <a class="mb-5" target="_blank" rel="noopener" href="http://es.presidencia.gov.co/"> <img
                  alt="GOBIERNO DE COLOMBIA"
                  [src]="LINK_LOGOS.LOGO_01"></a>
              <a class="mb-5" target="_blank" rel="noopener" href="http://es.presidencia.gov.co/"> <img
                  alt="PRESIDENCIA DE LA REPUBLICA"
                  [src]="LINK_LOGOS.LOGO_02"></a>
              <a class="mb-5" target="_blank" rel="noopener" href="http://www.cgfm.mil.co/"> <img alt="COMANDO GENERAL"
                  [src]="LINK_LOGOS.LOGO_03"></a>
              <a class="mb-5" target="_blank" rel="noopener" href="https://www.mindefensa.gov.co"> <img alt="MINDEFENSA"
                  [src]="LINK_LOGOS.LOGO_04"></a>
              <a class="mb-5" target="_blank" rel="noopener" href="https://www.mineducacion.gov.co"> <img
                  alt="MINEDUCACION"
                  [src]="LINK_LOGOS.LOGO_05"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="cat__footer__bottom">
        <div class="row">
          <div class="col-md-4">
            <img alt="certificacion" [src]="LINK_LOGOS.CERTIFICATION" width="12%">
          </div>
          <div class="col-md-4">
            <div [style]="{'text-align':'center'}">
              <span class="d-block"><b>Dirección: </b>{{SCHOOL_NAME === 'CELIC' ? 'Calle 100 #11 - 00 Bogotá, Colombia' : 'Calle 102 No 7 - 80'}}</span>
              <span class="d-block"><b>e-Mail: </b>{{SCHOOL_NAME === 'CELIC' ? 'direcciongeneral@liceosdelejercito.edu.co' : 'soporte@cedoc.edu.co'}}</span>
              <span class="d-block"><b> Horario de atención: </b>Lunes a jueves de 07:00 am a 1:00 pm | 2:00 pm a 4:00 pm. El día viernes de 7:00am a 2:00pm.</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="cat__footer__company">
              <span>
                © {{ currentDate.getFullYear() }} <a href="https://ejercito.mil.co/" target="_blank" rel="noopener">Ejército Nacional</a>
                <br>
                <br>
                All rights reserved
                <br>
                <a [href]="SCHOOL_NAME === 'CELIC' ? 'https://www.liceosejercito.edu.co/' : 'https://www.cemil.com/'" rel="noopener" target="_blank">Powered by {{SCHOOL_NAME === 'CELIC' ? 'LICEOS DEL EJÉRCITO NACIONAL' : 'CEDOC'}}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
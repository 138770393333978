import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  
  public baseUrlv2: string = environment.UrlApiV2;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ){ }

  getHeader(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

  httpGet(url: string): any {
    return this.http.get(this.baseUrlv2 + url, {headers: this.getHeader()} );
  }
  httpPost(url: string, data: any): any {
    return this.http.post(this.baseUrlv2 + url, data, {headers: this.getHeader()} );
  }
  httpDelete(url: string): any {
    return this.http.delete(this.baseUrlv2 + url, {headers: this.getHeader()} );
  }
  httpPatch(url: string, data: any): any {
    return this.http.patch(this.baseUrlv2 + url, data, {headers: this.getHeader()} );
  }

}

import { Injectable } from '@angular/core';

interface CourseOffer {
  celic: { [key: number]: number; };
  cedoc: { [key: number]: number; };
}

@Injectable({
  providedIn: 'root'
})
export class CourseOffersService {

  //School id - CourseOffer id
  private courses: CourseOffer = {
    celic: {
      1: 3657,
      4: 3674,
      5: 3675,
      6: 3676,
      7: 3677,
      8: 3678,
      9: 3679,
      10: 3680,
      25: 3681
    },
    cedoc: {
      1: 3675,
      4: 3676,
      5: 3677,
      6: 3678,
      7: 3679,
      8: 3680,
      9: 3681,
      10: 3682,
      16: 3683,
      17: 3684,
      18: 3685,
      19: 3686,
      20: 3687,
      21: 3688,
      22: 3689,
      23: 3690,
      24: 3691,
      25: 3692,
      26: 3693,
      27: 3694,
      28: 3695,
      29: 3696,
      30: 3697,
      31: 3698,
      32: 3699,
      33: 3700,
      34: 3701,
      35: 3702,
      36: 3703,
      37: 3704,
      38: 3706,
      39: 3707,
      40: 3708,
      41: 3916,
      42: 3917,
      43: 3918,
      44: 3919,
      45: 3920,
      46: 3921,
      47: 3922,
      48: 3923,
      49: 3924,
      50: 3925,
      51: 3926,
      52: 3927,
      53: 3928,
      54: 3929,
      55: 3930,
      56: 3931,
      57: 3932,
      58: 3933,
      59: 3934,
      60: 3935,
      61: 3936,
      62: 3937,
      63: 3938,
      64: 3939,
      65: 3940,
      66: 3941,
      67: 3942,
      68: 3943,
      69: 3944
    }
  };

  getCourseValue(school: string, schoolId: number): number | null {
    if (this.courses[school] && this.courses[school][schoolId])
      return this.courses[school][schoolId];

    return null;
  }
}

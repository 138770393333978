import { AfterViewInit, Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, take, throwError } from 'rxjs';
import { UsersField } from 'src/app/models/users/users-fields';
import { AuthService } from 'src/app/services/auth.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

declare const google: any;
let ctx: any;

@Component({
  selector: 'landing-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.css']
})
export class LoginCardComponent implements OnInit, AfterViewInit {

  @Output() closeBox: EventEmitter<any> = new EventEmitter();
  @Output() changeBox: EventEmitter<any> = new EventEmitter();

  public formModel: FormModel = {};
  user: UsersField = {};
  submitbuton: string = 'Aceptar';
  isok: boolean = false;
  errorMessage: string = '';
  showDataLogin: number = 0;
  disabled:boolean = false
  client =  google.accounts.id;

  public SCHOOL_NAME:string = environment.SCHOOL_NAME;
  public EMAIL_CLIENT_ID:string = environment.EMAIL_CLIENT_ID;



  
  constructor(
    private _usersService: UsersService,
    private _authService: AuthService,
    private _zone: NgZone,
    private _sweetalertService: SweetalertService,
    private _router: Router,
  ) {
    ctx=this;
    this.client.initialize({
      client_id: this.EMAIL_CLIENT_ID,
      callback: this.attachSignin,
    });
  }

 ngAfterViewInit(): void {
      
  this.loginGoogle()
 }

  ngOnInit(): void {/** */}

  public showErrorLoginCandidate: boolean = false;
  logInCandidate(model, isValid:boolean | null){
    if (isValid) {
      this.modalDefaultLoading('Iniciando sesión', 'Esta ventana se cerrara automaticamente, Espere por favor...');
      this._usersService.temporalLogin(model.Email,model.CellPhone).subscribe ({
        next: (p) => {
          this.modalClose();
          console.log('response: ',p);
          this.isok=true;
          localStorage.setItem("currentUser", p.userId);
          localStorage.setItem("accessToken", p.id);
          this._router.navigate(['/user/my-account']);
        },
        error: (e) => {
          this.submitbuton="Error en datos";
          this.errorMessage=e;
          this.modalErrorMsg('Error', 'Credenciales invalidas');
          this.isok=false;
          this.showErrorLoginCandidate=true;
        },
        complete: () => console.info('temporalLogin complete') 
      });
    }
  }
  loginGoogle() {
    this.client.renderButton(
      document.getElementById("googleBtn"),
      { theme: "filled_blue", size: "large" , text:"signin_with", shape:"pill"}  // customization attributes
    );
    this.client.prompt();
  }
  public attachSignin(response: any) {
    console.log(response.credential)
    let data = { 'id_token': response.credential };
    ctx.modalDefaultLoading('Iniciando sesión', 'Esta ventana se cerrara automaticamente, Espere por favor...');
    ctx._authService.loginGoogle(data).pipe(take(1)).subscribe(
      (data: { token: any; user: string; }) => {
        ctx._authService.setToken(data.token);
        localStorage.setItem("currentUser", data.user);
        ctx._zone.run(() => {
          window.location.href='/user/my-account';
        });
      },
      err => {
        console.log('Error login con google',err);
        let errorMsg = '';
        if (err?.error?.text) errorMsg = err.error.text;
        else errorMsg = 'Inténtelo de nuevo. Si el error persiste, póngase en contacto con el soporte.';
        ctx.modalErrorMsg('Error', errorMsg);
      }
    );
  }

  showHideLogin() {
    this.showDataLogin = 0
    this.closeBox.emit();
  }
  showHideLoginRegister() {
    this.changeBox.emit();
  }
  olvidoContrasena(){
    this.showDataLogin = 3
  }
  modalDefaultLoading(titulo: string, mensaje: string): void {
    this._sweetalertService.swalLoading(titulo, mensaje);
  }

  modalErrorMsg(titulo: string, mensaje: string): void {
    this._sweetalertService.swalErrorMsg(titulo, mensaje);
  }  
  SwalSuccess(titulo: string, mensaje: string, isValid:any): void {
    this._sweetalertService.swalSuccess(titulo, mensaje, isValid);
  }
  modalClose(): void {
    this._sweetalertService.swalClose();
  }

  restableceContrasena(model, isValid:boolean | null){
    this.disabled = true
    if (isValid) {
      try {
        this._sweetalertService.swalLoading("Cargando","Restableciendo contraseña")
        this._usersService.postContrasena(model.Email,  model.UserDocument)
        .pipe(
          catchError(({error}) => {
            if (error.status) {
              this.errorMessage = error.error.message;
              if (error.status === 404) {
                this.disabled= false
                this.modalErrorMsg('Error ', error.error.message);
              }else{
                this.disabled= false
              }
            } else if (error.error instanceof ErrorEvent) {
              this.disabled= false
              this.modalErrorMsg('Error', error.error.message);
            } else {
              this.disabled= false
              this.modalErrorMsg('Error', error.error.message);
            }
            return throwError(error.error.message);
          })
        )
        .subscribe(
          (response) => {
            this.user.Email = ''
            isValid = true
            this.disabled= false
            this.showDataLogin = 0
            this.SwalSuccess('Exitoso ', `Se ha reseateado la contraseña correctamente, verifique la nueva contraseña en su correo electrónico ${response.emailToSend} `, isValid)
          },
          (errorMessage) => {
            console.log("Dígite valores validos ",errorMessage )
            this.disabled= false
            this.modalErrorMsg('Error', errorMessage);
          }
        )
      } catch (error) {
        this.disabled= false
        console.log("error ", error)
        this.modalErrorMsg('Error', `${error}`);
        
      }
    }else{
      this.modalErrorMsg('Error', 'Dígite valores validos');
      this.errorMessage="Numero no valido"
    }
    
  }

}

export interface FormModel {
  captcha?: string; 
}
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Article } from 'src/app/models/article';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.css']
})
export class NewsViewComponent implements OnInit {

  public newsSelected: Article;
  public showNewsSelectedData: boolean = false;
  // @Input() articles: Article[];
  htmlContent: SafeHtml;
  @Input() set articles(data:Article[]){
    this.newsOnTheLeft = [];
    this.newsOnTheRight = [];
    this.allArticles = data;
    this.newsOnTheLeft = data.filter((item, index) => index % 2 === 0);
    this.newsOnTheRight = data.filter((item, index) => index % 2 !== 0);
  }
  allArticles: Article[] = [];
  newsOnTheLeft: Article[] = []
  newsOnTheRight: Article[] = []

  public DYNAMIC_SCHOOL_PHOTO:any = (id:number = 0) => environment.DYNAMIC_SCHOOL_PHOTO(id);

  constructor( private sanitizer: DomSanitizer) {
    /** */
  }

  ngOnInit(): void {
    /** */
  }
  setHtmlContent( stringHtml) {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(stringHtml);
    return this.htmlContent
  }
  showNewsSelected(index: number) {
    this.newsSelected = this.allArticles[index];

    if (!this.newsSelected.Imagen)
      this.newsSelected.Imagen = this.DYNAMIC_SCHOOL_PHOTO(this.newsSelected.SchoolID);

    if (!this.newsSelected.Description)
      this.newsSelected.Description = this.newsSelected.Summary;

    this.showNewsSelectedData = true;
  }

}
